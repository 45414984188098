<template>
    <div v-if="popup">
        <div class="popup">
            <div class="popup-inner">
                <div class="popup-body">
                    <button type="button" v-on:click="closePopUp()" class="close close-light" data-dismiss="modal" style="position:absolute;right:0;color:#fff;">X</button>
                    <ul style="background: #032400; padding:0; text-align:center;">
                        <img src="@/assets/images/popup.jpg" width="60%" >
                    </ul>
                    <!-- Call Me Form -->
                    <form @submit.prevent="postLead">
                        <div class="form-group">
                            <label class="label-control">Nome</label>
                            <input type="text" class="form-control" placeholder="Nome" v-model="lead.name" required>
                        </div>
                        <div class="form-group">
                            <label class="label-control">Telefone</label>
                            <input type="text" class="form-control" placeholder="Telefone" v-model="lead.phone" required>
                        </div>
                        <div class="form-group">
                            <label class="label-control">Email</label>
                            <input type="email" class="form-control" placeholder="Email" name="email" v-model="lead.email" required>
                        </div>
                        <div class="alert alert-secondary text-center" v-if="isSuccess">Cadastro realizado com sucesso!</div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-secondary float-right">ENVIAR</button>
                        </div>
                    </form>
                    <!-- end of call me form -->
                </div>
            </div>
        </div>
        <div class="popup-background"></div>
    </div>
</template>

<script>

import { mapMutations, mapState } from 'vuex'
import axios from 'axios'

export default {
    data(){
        return {
            lead:{
                name: '',
                phone: '',
                email: '',
            },
            isSuccess: false,
        }
    },
    mounted() {
        this.callPopUp()
    },
    computed: {
        ...mapState({
            popup: state => state.popup
        })
    },
    methods: {
        ...mapMutations({
            setPopUp: 'SET_POPUP'
        }),
        closePopUp () {
            this.setPopUp(false)
        },
        callPopUp: function () {
            setTimeout(() => {
                this.setPopUp(true)
            }, 1500);
        },
        postLead() {
            axios.post(
                `https://uniminasead.com.br/admin/api/postLead`,
                this.lead
            ).then(response => {
                this.isSuccess = true;
                console.log(response);
                setTimeout(() => {
                    this.setPopUp(false)
                }, 1000);
                
            });
        }
    }
}
</script>

<style scoped>
.close{
    padding: 10px;
}
.text-white{
    font-weight: bold;
}
.popup{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-background{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    background-color: rgba(0, 0, 0, 0.6);
}
form{
    padding: 40px 40px 80px;
}
.popup-inner{
    background: #62AF33;
    background: -moz-linear-gradient(235deg, #62AF33 0%, #ffffff 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, #62AF33), color-stop(100%, #ffffff));
    background: -webkit-linear-gradient(235deg, #62AF33 0%, #ffffff 100%);
    background: -o-linear-gradient(235deg, #62AF33 0%, #ffffff 100%);
    background: -ms-linear-gradient(235deg, #62AF33 0%, #ffffff 100%);
    background: linear-gradient(235deg, #62AF33 0%, #ffffff 100%);
    width: 40vw;
    position: absolute;
    top:0px;
    margin: 28px auto;
}

@media (max-width: 980px)
{
    .popup-inner{
        width: 95vw;
    }
}
</style>